<template>
  <div class="container mx-auto px-5">
    <div class="header">
    <a
      href="/"
    ><img src="../assets/logo_tac.png"/></a>
  </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class Header extends Vue {

  private title: string = "FP"
  created() {

  }
  mounted() {}
}
</script>
<style scoped>
.header {
  border-bottom: 2px #bbbbbb solid;
  height: 80px;
  padding-top: 17.5px;
}
.header img {
  height: 45px;
}

.header img:hover {
  opacity: 0.7;
}
</style>