
export default [
  {
    "id": 0,
    "name": "トレーニング試験",
    "no": 1,
    "grade": 3
},
{
  "id": -1,
  "name": "トレーニング試験",
  "no": 1,
  "grade": 2
},
  {
      "id": 1,
      "name": "FP技能検定３級学科試験",
      "no": 1,
      "grade": 3
  },
  {
    "id": 2,
    "name": "FP技能検定３級学科試験",
    "no": 2,
    "grade": 33
},
{
  "id": 20,
  "name": "FP技能検定２級試験",
  "no": 1,
  "grade": 2
},
]