<template>
  <div id="app">
    <div>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import BasicUtil from "./services/basicUtil";
@Component({
  components: {}
})
export default class App extends Vue {

  created() {
    
  }

  mounted() {
    
    // const agent = window.navigator.userAgent.toLowerCase();
    // if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
    // } else if (agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) {
    // } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    // } else if (agent.indexOf("chrome") != -1) {
    // } else if (agent.indexOf("safari") != -1) {
    //   window.onpopstate = function(event: any) {
    //     window.location.href = "/?error=back";
    //   };
    // } else if (agent.indexOf("firefox") != -1) {
    // } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    // }
  }
}
</script>
<style>

.container-xs {
  max-width: 960px !important;
}

.main-wrap {
   background: white;
   position: relative;
}

.h-100 {
  height: 100vh !important;
}

.udc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.f-lg {
  font-size: 1.5em !important;
}

.f-md {
  font-size: 1.2em !important;
}

.f-sm {
  font-size: 1.1em !important;
}

.title {
  font-size: 1.2em;
  padding: 0.5em;
  color: #666566;
  background: #f1eff1;
  border: solid 1px #e7e8e9;
  border-left: solid 5px #868486;
  margin-bottom: 1em;
}

button:disabled, button:disabled:hover, button:disabled:active, 
input[type=button]:disabled, input[type=button]:disabled:hover, input[type=button]:disabled:active{
  background-color: rgb(204,204,204);
  /*197,197,197*/
  -webkit-box-shadow: 0px 0px 0px 1px rgb(204,204,204),
                      0px 0px 0px 2px rgb(173,173,173);
  box-shadow: 0px 0px 0px 1px rgb(204,204,204),
              0px 0px 0px 2px rgb(173,173,173);
  color: rgb(135,135,135);
}

button, input[type=button]{ 
  outline: none;
  -webkit-transition: background-color .5s, -webkit-box-shadow .5s;
  transition: background-color .5s, -webkit-box-shadow .5s;
  -o-transition: box-shadow .5s, background-color .5s;
  transition: box-shadow .5s, background-color .5s;
  transition: box-shadow .5s, background-color .5s, -webkit-box-shadow .5s;
  font-family: sans-serif;
  background-color: rgb(235,235,235);
  font-weight: 600;
  padding: 1px 10px;
  margin: 2px;
  border: 0;
  border-radius: 1px;
  -webkit-box-shadow: 0px 0px 0px 1px rgb(225,225,225),
                      0px 0px 0px 2px rgb(173,173,173);
  box-shadow: 0px 0px 0px 1px rgb(225,225,225),
              0px 0px 0px 2px rgb(173,173,173);
  border: 1px dotted transparent;
  color: #555;
}

button:focus, input[type=button]:focus{
  
  background-color: rgb(235,235,235);
  -webkit-box-shadow: 0px 0px 0px 0px rgb(225,225,225),
                      0px 0px 0px 2px rgb(0,120,215);
  box-shadow: 0px 0px 0px 0px rgb(225,225,225),
              0px 0px 0px 2px rgb(0,120,215);
  border: 1px dotted rgb(17,17,17);
}

button:hover, input[type=button]:hover{
  -webkit-box-shadow: 0px 0px 0px 1px rgb(225,225,225),
                      0px 0px 0px 2px rgb(0,120,215);
  box-shadow: 0px 0px 0px 1px rgb(225,225,225),
              0px 0px 0px 2px rgb(0,120,215);
  background-color: rgb(229,241,251);
}
button:active, input[type=button]:active{
  -webkit-transition: background-color 0s;
  -o-transition: background-color 0s;
  transition: background-color 0s;
  background-color: rgb(204,228,247);
  -webkit-box-shadow: 0px 0px 0px 1px rgb(225,225,225),
                      0px 0px 0px 2px rgb(0,120,215);
  box-shadow: 0px 0px 0px 1px rgb(225,225,225),
              0px 0px 0px 2px rgb(0,120,215);
}

.basic-table {
  width: 100%;
}

.basic-table th {
  background: #dae6f2;
  color: #5b7497;
  text-align: center;
  border: solid 1px #cbcfd3;
  padding: 5px;
}
.basic-table td {
  border: solid 1px #cbcfd3;
  padding: 5px;
}

hr {
  height: 2px !important;
  border-bottom: 2px #bbbbbb solid;
  border: 0; 
}

.alert {
  background: #dae6f2;
  color: #5b7497;
  border: solid 1px #cbcfd3;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1.5em;
  display: inline-block;
}

</style>