<template>
<div class="container container-xs main-wrap h-100">
    <Header />
    <div class="p-5 main-body">
      <div class="mb-3">
        ボタン表示が「受検する」となっている試験がご利用可能です。<br>
        [受検する]ボタンを押して受検を開始してください。
        <br>※模擬試験問題が画面に表示された時点から試験時間を計測します。
        <br>※試験時間が経過すると模擬試験は自動的に終了します。
        <br><span class="text-danger">※受検中、以下の行為を行うとエラーとなりますのでご注意ください。</span>
        <ul>
        <li class="text-danger">ブラウザの戻るボタンをクリック</li>
        <li class="text-danger">ページの再読み込み</li>
        <li class="text-danger">タブを消去し、開き直す行為</li>
        </ul>
      </div>
      <div v-if="grade == '3'" class="px-4 mb-4">
        <h3 class="title">試験一覧</h3>

        <table class="basic-table">
          <tr>
            <th></th>
            <th style="width: 150px;">操作</th>
          </tr>
          <tr>
            <td>チュートリアル</td>
            <td>
              <button @click="execTuto()">受検する</button>
            </td>
          </tr>
          </table>

        <table class="basic-table">
          <tr>
            <th>試験1</th>
            <th>操作</th>
          </tr>
          <tr>
            <td>学科試験<br></td>
            <td style="width: 150px;">
              <button v-if="isAuth" @click="exec(1)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 個人資産相談業務</td>
            <td>
              <button v-if="isAuth" @click="execJitsugi(1, 2)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 保険顧客資産相談業務</td>
            <td>
              <button v-if="isAuth" @click="execJitsugi(1, 3)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 資産設計提案業務</td>
            <td>
              <button v-if="isAuth" @click="execJitsugi(1, 4)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
        </table>

        <table class="basic-table">
          <tr>
            <th>試験2</th>
            <th style="width: 150px;">操作</th>
          </tr>
          <tr>
            <td>学科試験<br></td>
            <td>
              <button v-if="isAuth33" @click="exec(2)">受検する</button>
              <button @click="backAuth2" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 個人資産相談業務</td>
            <td>
              <button v-if="isAuth33" @click="execJitsugi(2, 2)">受検する</button>
              <button @click="backAuth2" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 保険顧客資産相談業務</td>
            <td>
              <button v-if="isAuth33" @click="execJitsugi(2, 3)">受検する</button>
              <button @click="backAuth2" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 資産設計提案業務</td>
            <td>
              <button v-if="isAuth33" @click="execJitsugi(2, 4)">受検する</button>
              <button @click="backAuth2" v-else >認証する</button>
            </td>
          </tr>
        </table>
        
      </div>

      <div v-if="grade == '2'" class="px-4 mb-4">
        <h3 class="title">試験一覧</h3>

        <table class="basic-table">
          <tr>
            <th></th>
            <th style="width: 150px;">操作</th>
          </tr>
          <tr>
            <td>チュートリアル</td>
            <td>
              <button @click="execTuto2()">受検する</button>
            </td>
          </tr>
          </table>

        <table class="basic-table">
          <tr>
            <th>試験1</th>
            <th>操作</th>
          </tr>
          <tr>
            <td>学科試験<br></td>
            <td style="width: 150px;">
              <button v-if="isAuth" @click="exec(20)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 個人資産相談業務</td>
            <td>
              <button v-if="isAuth" @click="execJitsugi(20, 2)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 生保顧客資産相談業務</td>
            <td>
              <button v-if="isAuth" @click="execJitsugi(20, 3)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
          <tr>
            <td>実技試験 資産設計提案業務</td>
            <td>
              <button v-if="isAuth" @click="execJitsugi(20, 4)">受検する</button>
              <button @click="backAuth" v-else >認証する</button>
            </td>
          </tr>
        </table>
      </div>

    </div>
    <Footer />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import StorageService from "../services/storage";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Component({
  components: { Header, Footer }
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService();
  private isAllow: boolean = BasicUtil.isAllow();
  private grade: string = this.$route.params.grade ?? "0";
  private isAuth: boolean = false
  private isAuth33: boolean = false
  created() {
    document.body.style.background = "#dae6f2"
    if (this.grade == "3") {
      this.isAuth = BasicUtil.isAuth("3");
      this.isAuth33 = BasicUtil.isAuth("33");
      if (!this.isAuth && !this.isAuth33) this.$router.push("/auth/3");
    } else if (this.grade == "2") {
      this.isAuth = BasicUtil.isAuth("2");
      this.isAuth33 = BasicUtil.isAuth("22");
      if (!this.isAuth && !this.isAuth33) this.$router.push("/auth/2");
    }
    
  }
  mounted() {
    new BasicUtil().removeBasicSetting();
    new StorageService().removeCurrentData();
  }

  private execTuto() {
    this.$router.push(`/shiken/1/type/0/start`);
  }

  private execTuto2() {
    this.$router.push(`/shiken/1/type/-1/start`);
  }

  private exec(shikenId: number) {
    this.$router.push(`/shiken/${shikenId}/type/1/start`);
  }

  private execJitsugi(shikenId: number, type: number) {
    this.$router.push(`/shiken/${shikenId}/type/${type}/start`);
  }
  private backAuth() {
    this.$router.push("/auth/" + this.grade);
  }
  private backAuth2() {
    this.$router.push("/auth/" + this.grade);
  }
}
</script>
<style scoped>
.main-body {
  height: calc(100vh - 120px);
    overflow-y: scroll;
}
</style>
